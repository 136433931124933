<script setup lang="ts">
import {
	PhCaretRight,
	PhHeartStraight,
	PhX,
	PhMapPin,
} from '@phosphor-icons/vue';
import RewardIcon from '~/icons/RewardIcon.vue';
import { isRewardsEnabled } from '~/utils/featureFlags.ts';
import { useRewardsIndexQuery } from '~/data/rewards.ts';

const LoganStore = useLoganStore();
const WishListStore = useWishlistStore();

const CurrencyStore = useCurrency();
const { getFormattedPrice } = CurrencyStore;
const { currencySymbol } = storeToRefs(CurrencyStore);
const { data: rewardsIndexData } = useRewardsIndexQuery();

const { isSignedIn, isHvc } = storeToRefs(LoganStore);
const { wishlists } = storeToRefs(WishListStore);

const rewardsEnabled = ref<boolean>(false);
const rewardsBalance = computed(() => {
	return rewardsIndexData.value ? rewardsIndexData.value.balance : 0;
});

const emit = defineEmits<{
	(e: 'setSelectedTab', tab: string): void;
}>();

const setSelectedTab = inject('setSelectedTab') as (tab: string) => void;
const checkoutLocalization: Ref<boolean> = inject('checkoutLocalization')!;

const noAddress = computed(() => {
	if (LoganStore.addresses && Array.isArray(LoganStore.addresses)) {
		return LoganStore.addresses.length;
	}
	return 0;
});

watch(
	isSignedIn,
	() => {
		rewardsEnabled.value = isRewardsEnabled();
	},
	{
		immediate: true,
	},
);

const isOpen = ref(true);
const closePopup = () => {
	isOpen.value = false;
};

const GlobalStore = useGlobalStore();
</script>
<template>
	<div
		v-if="!LoganStore.isSignedIn"
		class="flex flex-col items-start justify-center bg-white p-3xl pb-0 login_button"
		@click="LoganStore.showLoginSheet = true"
	>
		<div
			class="flex justify-between items-center w-full"
			@click="LoganStore.requiresSignIn = false"
		>
			<div class="flex items-center flex-1 gap-3xl">
				<p class="text-lg font-semibold text-gray-900">Sign In / Register</p>
			</div>
			<PhCaretRight size="20" />
		</div>

		<div
			class="flex flex-row items-center justify-between py-3xl gap-3xl w-full"
		>
			<NuxtLink
				v-if="!isSignedIn && rewardsEnabled"
				class="flex flex-col items-center justify-between flex-1 gap-xs h-[56px] w-[156px]"
				@click="() => LoganStore.setRequiresSignIn()"
			>
				<div
					class="flex flex-wrap-reverse justify-start bg-gray-50 border-gray-200 border-[1px] rounded-xl p-md gap-sm w-full"
				>
					<div class="flex items-center p-md">
						<RewardIcon size="20" color="#CA8504" />
					</div>

					<div class="flex flex-col items-start justify-between gap-y-sm">
						<div class="font-semibold text-sm text-gray-800 h-5xl">-</div>
						<span class="font-normal text-xs text-center"> Rewards </span>
					</div>
				</div>
			</NuxtLink>
			<NuxtLink
				class="flex flex-col items-center flex-1 gap-xs h-[56px] w-[156px]"
				to="/wishlist"
				@click="() => LoganStore.setRequiresSignIn()"
			>
				<div
					class="flex flex-wrap-reverse justify-start bg-gray-50 border-gray-200 border-[1px] rounded-xl p-md gap-sm w-full"
				>
					<div class="flex items-center p-md">
						<PhHeartStraight size="24" color="#D92D20" />
					</div>

					<div class="flex flex-col items-start justify-between gap-y-sm">
						<span class="h-5xl">-</span>
						<span class="font-normal text-xs text-center"> Wishlist </span>
					</div>
				</div>
			</NuxtLink>
		</div>
	</div>
	<div v-else class="flex flex-col items-start justify-center bg-white pb-0">
		<div
			v-if="
				!LoganStore.firstName && isOpen && GlobalStore.storeType !== 'OCTANE'
			"
			class="gap-3xl flex flex-col items-center w-full bg-blue-50 h-[110px] p-3xl"
		>
			<div class="flex flex-col w-full">
				<div class="flex justify-between">
					<p class="text-sm font-semibold text-gray-700">
						Complete your Profile Details!
					</p>
					<PhX
						size="20"
						color="#A3A3A3"
						class="cursor-pointer"
						@click="closePopup()"
					/>
				</div>
				<p class="text-sm font-normal text-gray-600">
					Add details for a personalized experience.
				</p>
			</div>

			<div class="flex justify-center items-center w-full gap-xl">
				<NuxtLink
					class="flex items-center py-lg gap-3xl w-[328px] h-[36px] bg-gray-700 rounded-xs"
					:to="'/profile'"
					@click="emit('setSelectedTab', 'home')"
				>
					<p class="flex-1 text-sm font-medium text-white text-center">
						Update Profile
					</p>
				</NuxtLink>
			</div>
		</div>

		<div class="p-3xl pb-0 w-full">
			<NuxtLink
				class="w-full"
				:to="'/profile'"
				@click="emit('setSelectedTab', 'home')"
			>
				<div class="flex items-center justify-between bg-white gap-3xl w-full">
					<div class="flex items-center flex-1 gap-3xl">
						<!-- <div
							class="relative w-10xl h-10xl rounded-full bg-gray-100 border-[0.75px] border-black border-opacity-[0.08] flex justify-center items-center"
						>
							<UserIcon v-if="!nameInitials" />
							<p v-else class="text-lg font-semibold text-center text-gray-600">
								{{ nameInitials }}
							</p>

							<div
								v-if="isHvc"
								class="absolute bottom-0 right-0 gap-xs text-orange-600"
							>
								<HvcIcon />
							</div>
						</div> -->
						<div class="flex flex-col">
							<div class="font-semibold text-gray-900">
								<p v-if="LoganStore.firstName" class="text-lg">
									{{ LoganStore.firstName }} {{ LoganStore.lastName }}
								</p>
								<p v-else class="text-lg">New User</p>
							</div>

							<div class="text-sm text-gray-600">
								<p v-if="LoganStore.email">{{ LoganStore.email }}</p>
								<p v-else>{{ LoganStore.phoneNumber }}</p>
							</div>
						</div>
					</div>
					<PhCaretRight size="20" />
				</div>
			</NuxtLink>
		</div>

		<div
			v-if="GlobalStore.storeType !== 'OCTANE'"
			class="flex flex-row items-center justify-between p-3xl gap-3xl w-full"
		>
			<NuxtLink
				v-if="!isHvc"
				class="flex flex-col items-center justify-between flex-1 gap-xs h-[56px] w-[156px]"
				:to="isSignedIn ? '/rewards' : ''"
				@click="emit('setSelectedTab', 'home')"
			>
				<div
					class="flex flex-wrap-reverse justify-start bg-gray-50 border-gray-200 border-[1px] rounded-xl p-md gap-sm w-full"
				>
					<div class="flex items-center p-md">
						<RewardIcon size="20" color="#CA8504" />
					</div>

					<div class="flex flex-col items-start justify-between gap-y-sm">
						<div class="font-semibold text-sm text-gray-800 h-5xl">
							<span v-if="checkoutLocalization">
								{{ rewardsBalance.toLocaleString('en-US') }} points
							</span>
							<span v-else>
								{{ currencySymbol }} {{ getFormattedPrice(rewardsBalance) }}
							</span>
						</div>
						<span class="font-normal text-xs text-center"> Rewards </span>
					</div>
				</div>
			</NuxtLink>

			<NuxtLink
				v-else
				class="flex flex-row justify-between items-center flex-1 gap-xs text-center h-[56px] w-[156px]"
				:to="isSignedIn ? '/address-book' : ''"
				@click="emit('setSelectedTab', 'home')"
			>
				<div
					class="flex flex-wrap-reverse justify-start bg-gray-50 border-gray-200 border-[1px] rounded-xl p-md gap-sm w-full"
				>
					<div class="flex items-center p-md">
						<PhMapPin size="20" color="#079455" />
					</div>

					<div class="flex flex-col items-start justify-between gap-y-sm">
						<div class="h-5xl">
							<span class="font-semibold text-sm text-gray-800">
								{{ noAddress }} Saved
							</span>
						</div>
						<span v-if="noAddress > 1" class="font-normal text-xs text-center">
							Addresses
						</span>
						<span v-else class="font-normal text-xs text-center">
							Address
						</span>
					</div>
				</div>
			</NuxtLink>

			<NuxtLink
				class="flex flex-col items-center flex-1 gap-xs h-[56px] w-[156px]"
				to="/wishlist"
				@click="setSelectedTab('wishlist')"
			>
				<div
					class="flex flex-wrap-reverse justify-start bg-gray-50 border-gray-200 border-[1px] rounded-xl p-md gap-sm w-full"
				>
					<div class="flex items-center p-md">
						<PhHeartStraight size="24" color="#D92D20" />
					</div>

					<div class="flex flex-col items-start justify-between gap-y-sm">
						<div class="h-5xl">
							<p v-if="wishlists" class="font-semibold text-sm text-gray-800">
								{{ wishlists.favouriteList.totalProductsCount }} Liked
							</p>
						</div>
						<span class="font-normal text-xs text-center"> Wishlist </span>
					</div>
				</div>
			</NuxtLink>
		</div>
	</div>
</template>
