<script setup lang="ts">
interface RewardIcon {
	width: string;
	height: string;
	class: string;
}
const props = withDefaults(defineProps<RewardIcon>(), {
	width: '28',
	height: '28',
	class: '',
});
</script>
<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="18"
		viewBox="0 0 24 18"
		fill="none"
		:class="props.class"
	>
		<g filter="url(#filter0_dd_26_15011)">
			<rect
				x="1.22656"
				y="4.51099"
				width="21.6744"
				height="11.8421"
				rx="0.545455"
				transform="rotate(-0.0378517 1.22656 4.51099)"
				fill="url(#paint0_linear_26_15011)"
			/>
		</g>
		<g filter="url(#filter1_d_26_15011)">
			<rect
				x="1.22656"
				y="3.85181"
				width="21.6744"
				height="11.9585"
				rx="0.545455"
				transform="rotate(-0.0378517 1.22656 3.85181)"
				fill="url(#paint1_linear_26_15011)"
			/>
		</g>
		<g filter="url(#filter2_d_26_15011)">
			<rect
				x="1.22656"
				y="3.29712"
				width="21.6744"
				height="12.0088"
				rx="0.545455"
				transform="rotate(-0.0378517 1.22656 3.29712)"
				fill="url(#paint2_linear_26_15011)"
			/>
		</g>
		<path
			d="M17.0474 3.28271H22.321L22.3211 14.0574C22.3211 14.6599 21.8327 15.1484 21.2302 15.1484H17.0475L17.0474 3.28271Z"
			fill="#2C782E"
			fill-opacity="0.3"
		/>
		<g filter="url(#filter3_d_26_15011)">
			<path
				d="M1.22693 3.71448C1.22693 3.20022 1.22693 2.94309 1.38669 2.78333C1.54645 2.62357 1.80187 2.62357 2.31272 2.62357C5.01176 2.62357 12.1573 2.62357 17.0206 2.62357C17.9047 2.62357 18.7919 2.27953 19.553 1.85209C20.6227 1.25136 21.1575 0.950999 21.4283 1.11149C21.6991 1.27199 21.693 1.82628 21.6807 2.93488L21.5792 12.1439C21.5791 12.1497 21.5791 12.1525 21.5791 12.1537C21.5791 12.1548 21.5791 12.1546 21.5791 12.1547C21.5766 12.2794 21.4778 14.8188 17.4519 14.8188C14.0782 14.8188 5.3786 14.8188 2.31452 14.8188C1.80248 14.8188 1.54647 14.8188 1.38671 14.6591C1.22695 14.4993 1.22695 14.2422 1.22695 13.7279L1.22693 3.71448Z"
				fill="url(#paint3_linear_26_15011)"
			/>
		</g>
		<g style="mix-blend-mode: soft-light">
			<circle
				cx="12.1036"
				cy="8.88607"
				r="2.30721"
				fill="url(#paint4_linear_26_15011)"
			/>
		</g>
		<g style="mix-blend-mode: overlay">
			<rect
				x="2.70361"
				y="12.573"
				width="2.90856"
				height="0.581713"
				rx="0.290856"
				transform="rotate(0.631823 2.70361 12.573)"
				fill="#A3FFA7"
			/>
		</g>
		<g style="mix-blend-mode: overlay">
			<path
				d="M17.6655 4.00814C17.6382 3.8537 17.7335 3.70397 17.8842 3.66078C18.1801 3.57601 18.6632 3.42993 19.0251 3.28274C19.3644 3.14474 19.8282 2.91187 20.1404 2.75006C20.3091 2.66266 20.5156 2.7632 20.5486 2.95025C20.569 3.0659 20.5149 3.18176 20.4123 3.23899C20.1029 3.41164 19.442 3.77239 19.0251 3.94194C18.689 4.07864 18.2848 4.18319 18.0159 4.24486C17.8525 4.28233 17.6946 4.17325 17.6655 4.00814Z"
				fill="#A3FFA7"
			/>
		</g>
		<g style="mix-blend-mode: hard-light">
			<path
				d="M8.47765 14.8188V2.62354H5.18164V14.8188V16.6752H8.47765L8.47765 14.8188Z"
				fill="url(#paint5_linear_26_15011)"
			/>
		</g>
		<defs>
			<filter
				id="filter0_dd_26_15011"
				x="0.136142"
				y="3.95186"
				width="23.863"
				height="14.0368"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="0.272727" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.171434 0 0 0 0 0.471115 0 0 0 0 0.179251 0 0 0 1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_26_15011"
				/>
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="0.545455" />
				<feGaussianBlur stdDeviation="0.545455" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
				/>
				<feBlend
					mode="normal"
					in2="effect1_dropShadow_26_15011"
					result="effect2_dropShadow_26_15011"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect2_dropShadow_26_15011"
					result="shape"
				/>
			</filter>
			<filter
				id="filter1_d_26_15011"
				x="1.22705"
				y="3.83813"
				width="21.6812"
				height="12.2442"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="0.272727" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.171434 0 0 0 0 0.471115 0 0 0 0 0.179251 0 0 0 1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_26_15011"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_26_15011"
					result="shape"
				/>
			</filter>
			<filter
				id="filter2_d_26_15011"
				x="1.22705"
				y="3.28345"
				width="21.6812"
				height="12.2945"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="0.272727" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.171434 0 0 0 0 0.471115 0 0 0 0 0.179251 0 0 0 1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_26_15011"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_26_15011"
					result="shape"
				/>
			</filter>
			<filter
				id="filter3_d_26_15011"
				x="1.22705"
				y="1.0686"
				width="20.458"
				height="14.023"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="0.272727" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.171434 0 0 0 0 0.471115 0 0 0 0 0.179251 0 0 0 1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_26_15011"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_26_15011"
					result="shape"
				/>
			</filter>
			<linearGradient
				id="paint0_linear_26_15011"
				x1="22.8958"
				y1="8.33137"
				x2="0.762556"
				y2="13.2019"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#7FF682" />
				<stop offset="1" stop-color="#85C787" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_26_15011"
				x1="22.8958"
				y1="7.70974"
				x2="0.742732"
				y2="12.5372"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#7FF682" />
				<stop offset="1" stop-color="#85C787" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_26_15011"
				x1="22.8958"
				y1="7.17128"
				x2="0.734331"
				y2="11.9804"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#7FF682" />
				<stop offset="1" stop-color="#85C787" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_26_15011"
				x1="21.6618"
				y1="9.8748"
				x2="0.567367"
				y2="9.5452"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#9CF59E" />
				<stop offset="0.2467" stop-color="#4FD352" />
				<stop offset="1" stop-color="#47B34A" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_26_15011"
				x1="14.4097"
				y1="8.06752"
				x2="9.53981"
				y2="8.65303"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#E2FFE2" />
				<stop offset="1" stop-color="#CBDBCB" />
			</linearGradient>
			<linearGradient
				id="paint5_linear_26_15011"
				x1="6.82965"
				y1="2.62354"
				x2="6.82965"
				y2="17.7852"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="white" />
				<stop offset="0.772882" stop-color="#F8FFF8" />
				<stop offset="0.876414" stop-color="#C5C5C5" />
				<stop offset="0.983657" stop-color="#9A9A9A" />
			</linearGradient>
		</defs>
	</svg>
</template>
